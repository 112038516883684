.nav_header {
    background-color: #000000; }

.nav_brand {
    font-size: 32px;
    padding-right: 100px !important; }


.nav_header {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }

.content {
    padding-top: 7rem !important; }

img[alt=GaryFlynn] { width: 480px; };

.author_header { grid-template-columns: 6rem 1fr !important; row-gap: 200px !important; };

.post_tag {
    font-size: 0.65rem !important; }

img[alt=Achievement] { width: 160px; margin-top: 1px; margin-bottom: 5px;};

img[alt=VCP-CMA-2021] { width: 180px; margin-top: 1px; margin-bottom: 5px;};

img[alt=VCAP-CMA-Deploy-2021] { width: 180px; margin-top: 1px; margin-bottom: 5px;};

img[alt=vExpert-Cloud-Management] { width: 180px; margin-top: 1px; margin-bottom: 5px;};

img[alt=vExpert-4-Stars] { width: 180px; margin-top: 1px; margin-bottom: 5px;};

img[alt=vExpert-2023] { width: 180px; margin-top: 1px; margin-bottom: 5px;};

img[alt=vExpert-Pro] { width: 180px; margin-top: 1px; margin-bottom: 5px;};

img[alt=Terraform-Associate] { width: 180px; margin-top: 1px; margin-bottom: 5px;};

img[alt=Terraform-CHIP] { width: 180px; margin-top: 1px; margin-bottom: 5px;};

img[alt=HashiCorp-Ambassador-2023] { width: 180px; margin-top: 1px; margin-bottom: 5px;};

html {
  --bg: #0f171c; }

html {
    scroll-behavior: smooth; }

#achievements td, th { border: 0 !important; padding: 0.1rem !important; };
